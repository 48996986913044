var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"article"},[_c('div',{staticClass:"aside_management_mode raw_regist_mode"},[_vm._m(0),_c('div',{staticClass:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',[_c('div',{staticClass:"input_text"},[_c('label',{staticClass:"require",class:{
                base_info: _vm.checkValid.includes('원자재 구분') && _vm.baseInfo,
              }},[_vm._v("원자재 구분")]),(_vm.showSelectric)?_c('my-selectric',{attrs:{"id":'material_type_selectric',"watch":_vm.newData.resource_type_id,"options":_vm.material_options,"commit":'setNewMaterialTypeId'}}):_vm._e()],1),_c('div',{staticClass:"input_text"},[_c('label',{staticClass:"require",class:{
                base_info: _vm.checkValid.includes('원자재명') && _vm.baseInfo,
              }},[_vm._v("원자재명")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newData.name),expression:"newData.name"}],attrs:{"type":"text","placeholder":"과당","required":""},domProps:{"value":(_vm.newData.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newData, "name", $event.target.value)}}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.newData.material_group_yn),expression:"!newData.material_group_yn"}],staticClass:"input_text"},[_c('label',{staticClass:"require",class:{
                base_info: _vm.checkValid.includes('입고단위') && _vm.baseInfo,
              }},[_vm._v("입고단위")]),(_vm.showSelectric)?_c('my-selectric',{attrs:{"id":'incoming_unit_selectric',"watch":_vm.newData.incoming_unit_id,"options":_vm.unit_options,"commit":'setNewMaterialUnitsIncoming'}}):_vm._e()],1),_c('div',{staticClass:"input_text"},[_c('label',{staticClass:"require",class:{
                base_info: _vm.checkValid.includes('사용단위') && _vm.baseInfo,
              }},[_vm._v("사용단위")]),(_vm.showSelectric)?_c('my-local-selectric',{attrs:{"id":'using_unit_selectric',"watch":_vm.newData.using_unit_id,"options":_vm.unit_options},on:{"changeValue":function($event){return _vm.changeUnitsUsing($event)}}}):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.getConversionRatio != undefined && !_vm.newData.material_group_yn
            ),expression:"\n              getConversionRatio != undefined && !newData.material_group_yn\n            "}],staticClass:"warning"},[_vm._v(" "+_vm._s(_vm.getConversionRatio)+" ")])]),_c('div',[_c('div',{staticClass:"input_text"},[_c('label',[_vm._v("규격")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newData.standard),expression:"newData.standard"}],attrs:{"type":"text","placeholder":"1Box / 20Kg 5EA"},domProps:{"value":(_vm.newData.standard)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newData, "standard", $event.target.value)}}})]),(_vm.systemCompany['group_material_yn'])?_c('div',{staticClass:"input_checkbox"},[_c('label',{staticClass:"label_txt",attrs:{"for":"checkbox2"}},[_vm._v("그룹 자재")]),_c('label',{staticClass:"chk_box",class:_vm.newData.material_group_yn ? 'active' : '',attrs:{"for":"checkbox2"}},[_c('i',{staticClass:"fa fa-check"})]),_c('input',{attrs:{"type":"checkbox","id":"checkbox2"},on:{"click":function($event){return _vm.$store.commit(
                  'setNewMaterialGroupYnToMaterial',
                  !_vm.newData.material_group_yn
                )}}})]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.newData.material_group_yn),expression:"!newData.material_group_yn"}],staticClass:"input_text inputs"},[_c('label',[_vm._v("발주단위")]),_c('input',{attrs:{"type":"text","placeholder":"5","inputmode":"decimal"},domProps:{"value":_vm.$makeComma(_vm.newData.order_unit)},on:{"input":function($event){return _vm.$inputNumber($event, _vm.newData, 'order_unit')}}}),_c('label',[_vm._v(_vm._s(_vm.newData.incoming_unit_id != null && _vm.newData.using_unit_id != null ? _vm.getUnitName(_vm.newData.incoming_unit_id) : ''))])])]),_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.newData.material_group_yn),expression:"!newData.material_group_yn"}],staticClass:"input_text"},[_c('label',{staticClass:"require",class:{
                base_info: _vm.checkValid.includes('수입검사') && _vm.baseInfo,
              }},[_vm._v("수입검사")]),(_vm.showSelectric)?_c('my-selectric',{attrs:{"id":'inspection_selectric',"watch":_vm.newData.inspection,"options":_vm.inspection_options,"commit":'setNewMaterialInspection'}}):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.newData.material_group_yn),expression:"!newData.material_group_yn"}],staticClass:"input_text"},[_c('label',[_vm._v("창고")]),(_vm.showSelectric)?_c('my-selectric',{attrs:{"id":'store_selectric',"watch":_vm.newData.default_store_id,"options":_vm.store_options,"commit":'setNewMaterialDefaultStore'}}):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.newData.material_group_yn),expression:"!newData.material_group_yn"}],staticClass:"input_text inputs"},[_c('label',[_vm._v("적정 재고량")]),_c('input',{attrs:{"type":"text","placeholder":"20","inputmode":"decimal"},domProps:{"value":_vm.$makeComma(_vm.newData.stock_size)},on:{"input":function($event){return _vm.$inputNumber($event, _vm.newData, 'stock_size')}}}),_c('label',[_vm._v(_vm._s(_vm.newData.incoming_unit_id != null && _vm.newData.using_unit_id != null ? _vm.getUnitName(_vm.newData.incoming_unit_id) : ''))])])]),_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.newData.material_group_yn),expression:"!newData.material_group_yn"}],staticClass:"input_text"},[_c('label',{staticClass:"require",class:{
                base_info: _vm.checkValid.includes('과세 여부') && _vm.baseInfo,
              }},[_vm._v("과세 여부")]),(_vm.showSelectric)?_c('my-selectric',{attrs:{"id":'tax_selectric',"watch":_vm.newData.tax,"options":_vm.tax_options,"commit":'setNewMaterialTax'}}):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.newData.material_group_yn),expression:"!newData.material_group_yn"}],staticClass:"input_text inputs"},[_c('label',[_vm._v("바코드")]),_c('input',{attrs:{"type":"text","maxlength":"15","inputmode":"decimal"},domProps:{"value":_vm.newData.barcode},on:{"input":function($event){$event.target.value = $event.target.value.replace(
                  /[^0-9]/gi,
                  ''
                );
                _vm.newData.barcode = $event.target.value;}}})])]),_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.newData.material_group_yn),expression:"!newData.material_group_yn"}],staticClass:"input_text inputs add"},[_c('label',[_vm._v("구매처")]),_c('div',[_c('draggable',{staticClass:"selected_items_wrap",attrs:{"list":_vm.newCompanys,"delay-on-touch-only":"true","delay":"80"}},_vm._l((_vm.newCompanys),function(newCompany,index){return _c('div',{key:newCompany.id,staticClass:"selected_item",class:index == 0 ? 'default' : ''},[_c('span',[_vm._v(_vm._s(newCompany.name))]),_c('button',{staticClass:"delete_item",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteNewCompany(index)}}})])}),0),_c('div',[_c('button',{staticClass:"btn_ledger",attrs:{"type":"button"},on:{"click":function($event){_vm.showCompanySearch = true}}},[_vm._v(" 구매처 선택 ")])])],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.newData.material_group_yn),expression:"newData.material_group_yn"}],staticClass:"input_text inputs add"},[_c('label',[_vm._v("자재 그룹")]),_c('div',[_c('draggable',{staticClass:"selected_items_wrap",model:{value:(_vm.groupMaterials),callback:function ($$v) {_vm.groupMaterials=$$v},expression:"groupMaterials"}},_vm._l((_vm.groupMaterials),function(groupMaterial,index){return _c('div',{key:groupMaterial.id,staticClass:"selected_item"},[_c('span',[_vm._v(_vm._s(groupMaterial.name)+" "+_vm._s(groupMaterial.standard.trim() != '' && groupMaterial.standard != null ? (" - " + (groupMaterial.standard)) : ''))]),_c('button',{staticClass:"delete_item",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteGroupMaterial(index)}}})])}),0),_c('div',[_c('button',{staticClass:"btn_ledger",attrs:{"type":"button"},on:{"click":function () {
                      _vm.ignore_list = _vm.groupMaterials;
                      _vm.showMaterialSearch = true;
                    }}},[_vm._v(" 자재 선택 ")])])],1)])]),_c('div'),_c('div'),_c('div',{staticClass:"remark"},[_c('div',{staticClass:"input_text"},[_c('label',[_vm._v("비고")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.newData.detail),expression:"newData.detail"}],domProps:{"value":(_vm.newData.detail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newData, "detail", $event.target.value)}}})])]),_c('div',{staticClass:"btn_wrap"},[_c('button',{staticClass:"btn_sub2",attrs:{"type":"button"},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v(" 저장 ")])])])])]),(_vm.showCompanySearch)?_c('company-search',{attrs:{"search_type":2},on:{"onclose":function($event){_vm.showCompanySearch = false},"onselect":function($event){return _vm.pushNewCompany($event)}}}):_vm._e(),(_vm.showMaterialSearch)?_c('resource-search',{attrs:{"filter_type":1,"ignore_list":_vm.ignore_list},on:{"onclose":function($event){_vm.showMaterialSearch = false},"onselect":function ($event) {
        if (_vm.newData.using_unit_id != $event.using_unit_id) {
          _vm.openOneButtonModal(
            '선택 불가',
            '그룹 자재와 포함 자재의 사용단위는 동일해야합니다.'
          );
          return;
        }
        _vm.groupMaterials.push($event);
        _vm.showMaterialSearch = false;
      }}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"head"},[_c('h5',[_vm._v("원자재 등록")])])}]

export { render, staticRenderFns }