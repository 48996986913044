<template>
  <div id="contents" class="raw_materials">
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToMaterialPage'"
          :options="[
            { title: '조회&수정', name: 'modification' },
            { title: '등록', name: 'new' },
          ]"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <a
            @click="ToggleManagementMode()"
            :class="managementMode ? 'btn_admin on' : 'btn_admin'"
            v-if="isPermissionOn && tabIndex == 0"
            href="#"
            >관리</a
          >
          <!-- <button class="btn_admin" :disabled="!managementMode">저장</button> -->
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li :class="tabIndex == 0 ? 'active' : ''">
            <a @click="SetOpenTabIndex(0)">조회&수정</a>
          </li>
          <li :class="tabIndex == 1 ? 'active' : ''" v-if="isPermissionOn">
            <a @click="SetOpenTabIndex(1)">등록</a>
          </li>
        </ul>
      </div>
      <material-modification-form
        v-if="tabIndex == 0"
      ></material-modification-form>
      <material-new-form v-if="tabIndex == 1"></material-new-form>
    </div>
  </div>
</template>

<script>
import MaterialModificationForm from '@/views/forms/Base/MaterialModificationForm.vue';
import MaterialNewForm from '@/views/forms/Base/MaterialNewForm.vue';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import SpinnerModal from '@/mixins/spinner';
import ModalMixin from '@/mixins/modal';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import { mapGetters, mapMutations } from 'vuex';
export default {
  mixins: [ClosePageMixin, SpinnerModal, ModalMixin, FavoriteMixin],
  components: {
    MaterialModificationForm,
    MaterialNewForm,
    AsideSelectric,
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromMaterialPage',
      tabIndex: 'getOpenTabIndexFromMaterialPage',
      resource_type: 'getResourceType',
    }),
  },
  methods: {
    ...mapMutations({
      ToggleManagementMode: 'toggleManagementModeToMaterialPage',
      SetOpenTabIndex: 'setOpenTabIndexToMaterialPage',
    }),
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitMaterialPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style scoped></style>
