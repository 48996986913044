<template>
  <div class="article">
    <div class="aside_management_mode raw_regist_mode">
      <div class="head">
        <h5>원자재 등록</h5>
      </div>
      <div class="form">
        <form @submit.prevent>
          <div>
            <div class="input_text">
              <label
                class="require"
                :class="{
                  base_info: checkValid.includes('원자재 구분') && baseInfo,
                }"
                >원자재 구분</label
              >
              <my-selectric
                v-if="showSelectric"
                :id="'material_type_selectric'"
                :watch="newData.resource_type_id"
                :options="material_options"
                :commit="'setNewMaterialTypeId'"
              >
              </my-selectric>
            </div>
            <div class="input_text">
              <label
                class="require"
                :class="{
                  base_info: checkValid.includes('원자재명') && baseInfo,
                }"
                >원자재명</label
              >
              <input
                type="text"
                placeholder="과당"
                required
                v-model="newData.name"
              />
            </div>

            <div class="input_text" v-show="!newData.material_group_yn">
              <label
                class="require"
                :class="{
                  base_info: checkValid.includes('입고단위') && baseInfo,
                }"
                >입고단위</label
              >
              <my-selectric
                v-if="showSelectric"
                :id="'incoming_unit_selectric'"
                :watch="newData.incoming_unit_id"
                :options="unit_options"
                :commit="'setNewMaterialUnitsIncoming'"
              >
              </my-selectric>
            </div>
            <div class="input_text">
              <label
                class="require"
                :class="{
                  base_info: checkValid.includes('사용단위') && baseInfo,
                }"
                >사용단위</label
              >
              <my-local-selectric
                v-if="showSelectric"
                :id="'using_unit_selectric'"
                :watch="newData.using_unit_id"
                :options="unit_options"
                @changeValue="changeUnitsUsing($event)"
              >
              </my-local-selectric>
            </div>
            <div
              v-show="
                getConversionRatio != undefined && !newData.material_group_yn
              "
              class="warning"
            >
              {{ getConversionRatio }}
            </div>
          </div>
          <div>
            <div class="input_text">
              <label>규격</label>
              <input
                type="text"
                placeholder="1Box / 20Kg 5EA"
                v-model="newData.standard"
              />
            </div>
            <div
              class="input_checkbox"
              v-if="systemCompany['group_material_yn']"
            >
              <label for="checkbox2" class="label_txt">그룹 자재</label>
              <label
                for="checkbox2"
                class="chk_box"
                :class="newData.material_group_yn ? 'active' : ''"
              >
                <i class="fa fa-check"></i>
              </label>
              <input
                type="checkbox"
                id="checkbox2"
                @click="
                  $store.commit(
                    'setNewMaterialGroupYnToMaterial',
                    !newData.material_group_yn,
                  )
                "
              />
            </div>
            <div class="input_text inputs" v-show="!newData.material_group_yn">
              <label>발주단위</label>
              <input
                type="text"
                placeholder="5"
                :value="$makeComma(newData.order_unit)"
                inputmode="decimal"
                @input="$inputNumber($event, newData, 'order_unit')"
              />
              <label>{{
                newData.incoming_unit_id != null &&
                newData.using_unit_id != null
                  ? getUnitName(newData.incoming_unit_id)
                  : ''
              }}</label>
            </div>
          </div>
          <div>
            <div class="input_text" v-show="!newData.material_group_yn">
              <label
                class="require"
                :class="{
                  base_info: checkValid.includes('수입검사') && baseInfo,
                }"
                >수입검사</label
              >
              <my-selectric
                v-if="showSelectric"
                :id="'inspection_selectric'"
                :watch="newData.inspection"
                :options="inspection_options"
                :commit="'setNewMaterialInspection'"
              >
              </my-selectric>
            </div>
            <div class="input_text" v-show="!newData.material_group_yn">
              <label>창고</label>
              <my-selectric
                v-if="showSelectric"
                :id="'store_selectric'"
                :watch="newData.default_store_id"
                :options="store_options"
                :commit="'setNewMaterialDefaultStore'"
              >
              </my-selectric>
            </div>
            <div class="input_text inputs" v-show="!newData.material_group_yn">
              <label>적정 재고량</label>
              <input
                type="text"
                placeholder="20"
                :value="$makeComma(newData.stock_size)"
                inputmode="decimal"
                @input="$inputNumber($event, newData, 'stock_size')"
              />
              <label>{{
                newData.incoming_unit_id != null &&
                newData.using_unit_id != null
                  ? getUnitName(newData.incoming_unit_id)
                  : ''
              }}</label>
            </div>
          </div>
          <div>
            <div class="input_text" v-show="!newData.material_group_yn">
              <label
                class="require"
                :class="{
                  base_info: checkValid.includes('과세 여부') && baseInfo,
                }"
                >과세 여부</label
              >
              <my-selectric
                v-if="showSelectric"
                :id="'tax_selectric'"
                :watch="newData.tax"
                :options="tax_options"
                :commit="'setNewMaterialTax'"
              >
              </my-selectric>
              <!-- <label
                for="checkbox1"
                class="chk_box"
                :class="newData.tax ? 'active' : ''"
              >
                <i class="fa fa-check"></i>
              </label>
              <input
                type="checkbox"
                id="checkbox1"
                @click="newData.tax = !newData.tax"
              /> -->
            </div>
            <div class="input_text inputs" v-show="!newData.material_group_yn">
              <label>바코드</label>
              <input
                type="text"
                :value="newData.barcode"
                maxlength="15"
                inputmode="decimal"
                @input="
                  $event.target.value = $event.target.value.replace(
                    /[^0-9]/gi,
                    '',
                  );
                  newData.barcode = $event.target.value;
                "
              />
            </div>
          </div>
          <div>
            <div
              class="input_text inputs add"
              v-show="!newData.material_group_yn"
            >
              <label>구매처</label>
              <div>
                <draggable
                  class="selected_items_wrap"
                  :list="newCompanys"
                  delay-on-touch-only="true"
                  delay="80"
                >
                  <div
                    class="selected_item"
                    :class="index == 0 ? 'default' : ''"
                    v-for="(newCompany, index) in newCompanys"
                    :key="newCompany.id"
                  >
                    <span>{{ newCompany.name }}</span
                    ><button
                      type="button"
                      class="delete_item"
                      @click.prevent.stop="deleteNewCompany(index)"
                    ></button>
                  </div>
                </draggable>
                <div>
                  <button
                    type="button"
                    class="btn_ledger"
                    @click="showCompanySearch = true"
                  >
                    구매처 선택
                  </button>
                </div>
              </div>
            </div>
            <div
              class="input_text inputs add"
              v-show="newData.material_group_yn"
            >
              <label>자재 그룹</label>
              <div>
                <draggable class="selected_items_wrap" v-model="groupMaterials">
                  <div
                    class="selected_item"
                    v-for="(groupMaterial, index) in groupMaterials"
                    :key="groupMaterial.id"
                  >
                    <span
                      >{{ groupMaterial.name }}
                      {{
                        groupMaterial.standard.trim() != '' &&
                        groupMaterial.standard != null
                          ? ` - ${groupMaterial.standard}`
                          : ''
                      }}</span
                    ><button
                      type="button"
                      class="delete_item"
                      @click="deleteGroupMaterial(index)"
                    ></button>
                  </div>
                </draggable>
                <div>
                  <button
                    type="button"
                    class="btn_ledger"
                    @click="
                      () => {
                        ignore_list = groupMaterials;
                        showMaterialSearch = true;
                      }
                    "
                  >
                    자재 선택
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div></div>
          <div></div>
          <div class="remark">
            <div class="input_text">
              <label>비고</label>
              <textarea v-model="newData.detail"> </textarea>
            </div>
          </div>
          <div class="btn_wrap">
            <button type="button" class="btn_sub2" @click="submitForm()">
              저장
            </button>
          </div>
        </form>
      </div>
    </div>
    <company-search
      v-if="showCompanySearch"
      :search_type="2"
      @onclose="showCompanySearch = false"
      @onselect="pushNewCompany($event)"
    ></company-search>
    <resource-search
      v-if="showMaterialSearch"
      :filter_type="1"
      :ignore_list="ignore_list"
      @onclose="showMaterialSearch = false"
      @onselect="
        $event => {
          if (newData.using_unit_id != $event.using_unit_id) {
            openOneButtonModal(
              '선택 불가',
              '그룹 자재와 포함 자재의 사용단위는 동일해야합니다.',
            );
            return;
          }
          groupMaterials.push($event);
          showMaterialSearch = false;
        }
      "
    ></resource-search>
  </div>
</template>

<script>
import MySelectric from '@/layouts/components/MySelectric';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import CompanySearch from '@/layouts/components/search-popup/CompanySearch';
import ResourceSearch from '@/layouts/components/search-popup/ResourceSearch';
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';

export default {
  mixins: [ModalMixin, SpinnerMixin],
  data() {
    return {
      showCompanySearch: false,
      showMaterialSearch: false,
      showSelectric: false,
      ignore_list: [],
      inspection_options: [
        { label: '선택', value: null },
        { label: '생략', value: false },
        { label: '필요', value: true },
      ],
      tax_options: [
        { label: '선택', value: null },
        { label: '과세', value: true },
        { label: '면세', value: false },
      ],
      baseInfo: false,
    };
  },
  components: {
    MySelectric,
    CompanySearch,
    draggable,
    ResourceSearch,
    MyLocalSelectric,
  },
  computed: {
    ...mapGetters({
      material_options: 'getMaterialTypeForSelectricSelect',
      units: 'getUnitCodes',
      unit_conversions: 'getUnitConversion',
      unit_options: 'getUnitForSelectric',
      newData: 'getNewDataFromMaterial',
      store_options: 'getStoresForSelectric',
      stores: 'getStores',
      systemCompany: 'getSystemCompany',
      materials: 'getMaterial',
    }),
    newCompanys: {
      get() {
        return this.$store.getters.getNewCompanyFromMaterial;
      },
      set(value) {
        this.$store.commit('setNewMaterialCompany', value);
      },
    },
    groupMaterials: {
      get() {
        return this.$store.getters.getGroupMaterialFromMaterial;
      },
      set(value) {
        this.$store.commit('setGroupMaterialToMaterial', value);
      },
    },
    getConversionRatio() {
      if (this.newData.incoming_unit_id == this.newData.using_unit_id) {
        return undefined;
      }

      let ratio = this.unit_conversions.find(
        x =>
          x.in_unit_id == this.newData.incoming_unit_id &&
          x.out_unit_id == this.newData.using_unit_id,
      );
      return ratio == undefined
        ? '단위 환산식이 정의되어 있지 않습니다. 1:1 관계로 계산됩니다.'
        : `환산단위 : ${ratio.cvr_ratio}`;
    },
    checkValid() {
      let chk = [];
      if (
        this.newData.name == undefined ||
        this.newData.name.trim().length == 0
      ) {
        chk.push('원자재명');
      }

      if (
        this.newData.resource_type_id == undefined ||
        this.newData.resource_type_id == null
      ) {
        chk.push('원자재 구분');
      }

      if (
        !this.newData.material_group_yn &&
        (this.newData.incoming_unit_id == undefined ||
          this.newData.incoming_unit_id == null)
      ) {
        chk.push('입고단위');
      }

      if (
        this.newData.using_unit_id == undefined ||
        this.newData.using_unit_id == null
      ) {
        chk.push('사용단위');
      }

      if (
        !this.newData.material_group_yn &&
        (this.newData.inspection == undefined ||
          this.newData.inspection == null)
      ) {
        chk.push('수입검사');
      }

      if (
        !this.newData.material_group_yn &&
        (this.newData.tax == undefined || this.newData.tax == null)
      ) {
        chk.push('과세 여부');
      }

      return chk;
    },
    validName() {
      const materialListChk = this.lodash
        .clonedeep(this.materials)
        .find(
          x =>
            x.name == this.newData.name.trim() &&
            x.standard == this.newData.standard.trim(),
        );

      if (materialListChk != undefined) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    getUnitName(id) {
      let hitValue = this.unit_options.find(x => x.value == id);
      return hitValue == undefined ? '' : hitValue.label;
    },
    pushNewCompany(arg) {
      console.log(arg);
      if (this.newCompanys.find(x => x.id == arg.id) != undefined) {
        this.showCompanySearch = false;
        return;
      }
      arg.primary_comp = false;
      this.newCompanys.push(arg);
      this.showCompanySearch = false;
    },
    deleteNewCompany(index) {
      this.newCompanys.splice(index, 1);
    },
    deleteGroupMaterial(index) {
      this.groupMaterials.splice(index, 1);
    },

    async FETCHS(str) {
      this.showSpinner();
      await this.$store
        .dispatch(str)
        .then(() => {})
        .catch(() => {
          this.openOneButtonModal(
            '로드 중 오류',
            '자재 관련 정보 로드 중 오류',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },

    async submitForm() {
      if (this.checkValid.length == 0 && !this.validName) {
        const payload = this.lodash.clonedeep(this.newData);
        if (payload.material_group_yn) {
          payload.incoming_unit_id = payload.using_unit_id;
          payload.inspection = false;
          payload.tax = false;
        }
        payload.name = payload.name.trim();
        payload.standard = payload.standard.trim();
        payload.order_unit = this.$makeNumber(payload.order_unit);
        payload.stock_size = this.$makeNumber(payload.stock_size);
        this.showSpinner();
        await this.$store
          .dispatch('INSERT_MATERIAL', payload)
          .then(async response => {
            if (this.newCompanys.length > 0) {
              this.newCompanys[0].primary_comp = true;
              await this.INSERT_MATERIAL_COMPANY(response.data.data.id);
            } else if (this.groupMaterials.length > 0) {
              await this.INSERT_MATERIAL_GROUP(response.data.data.id);
            }

            this.openOneButtonModal('등록 성공', '성공적으로 등록하였습니다.');
            this.baseInfo = false;
            this.$store.commit('setNewDataToMaterial', {
              name: '',
              detail: '',
              standard: '',
              inspection: null,
              stock_size: 0,
              material_group_yn: false,
              tax: null,
              barcode: '',
              resource_type_id: null,
              incoming_unit_id: null,
              using_unit_id: null,
              default_store_id: null,
              order_unit: 0,
            });
            this.$store.commit('setGroupMaterialToMaterial', []);
            this.$store.commit('setNewMaterialCompany', []);
            this.$store.dispatch('FETCH_MATERIAL_WITH_COMPANY');
          })
          .catch(error => {
            console.log(error);
            this.openOneButtonModal('저장 중 에러', '원자재 저장 중 에러발생');
          })
          .finally(() => {
            this.hideSpinner();
          });
      } else if (this.checkValid.length > 0) {
        this.openOneButtonModal(
          '등록 불가',
          `${this.checkValid.join()}은(는) 반드시 입력하여 주십시오.`,
        );
        this.baseInfo = true;
      } else if (this.validName) {
        this.openOneButtonModal(
          '등록 불가',
          `같은 이름의 원자재가 등록이 되어있습니다.<br />다른 원자재명을 등록해주세요.`,
        );
      }
    },
    async INSERT_MATERIAL_COMPANY(mat_id) {
      if (this.newCompanys.length > 0) {
        let dl = [];
        this.newCompanys.forEach(el => {
          dl.push({
            material_id: mat_id,
            company_id: el.id,
            primary_comp: false,
          });
        });
        dl[0].primary_comp = true;
        await this.$store
          .dispatch('INSERT_MATERIAL_COMPANY_LIST', { data_list: dl })
          .then(() => {})
          .catch(error => {
            console.log(error);
            this.openOneButtonModal('저장 중 에러', '원자재 저장 중 에러발생');
          })
          .finally(() => {});
      }
    },
    async INSERT_MATERIAL_GROUP(mat_id) {
      if (this.groupMaterials.length > 0) {
        let dl = [];
        this.groupMaterials.forEach(el => {
          dl.push({
            group_material_id: mat_id,
            member_material_id: el.id,
          });
        });
        await this.$store
          .dispatch('INSERT_MATERIAL_GROUP_LIST', { data_list: dl })
          .then(() => {})
          .catch(error => {
            console.log(error);
            this.openOneButtonModal(
              '저장 중 에러',
              '원자재 그룹 저장 중 에러발생',
            );
          })
          .finally(() => {});
      }
    },
    changeUnitsUsing(value) {
      this.$store.commit('setNewMaterialUnitsUsing', value);
      this.$store.commit('setGroupMaterialToMaterial', []);
    },
  },
  async created() {
    if (this.material_options.length < 2) {
      await this.FETCHS('FETCH_RESOURCE_TYPE');
    }
    if (this.units.length == 0) {
      await this.FETCHS('FETCH_UNIT');
    }
    if (this.unit_conversions.length == 0) {
      await this.FETCHS('FETCH_UNIT_CONVERSION');
    }
    if (this.stores.length == 0) {
      await this.FETCHS('FETCH_STORE');
    }

    this.showSelectric = true;
  },
};
</script>

<style scoped></style>
