<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <span class="option_txt">원자재 현황</span>
        <div>
          <span class="option_txt">원자재 구분</span>
          <div>
            <my-local-selectric
              v-if="showSelectric"
              :id="'search_type_selectric'"
              :watch="search_type"
              :options="
                material_options_all.concat({ label: '삭제기록', value: 9 })
              "
              @changeValue="changeSelectric($event)"
            >
            </my-local-selectric>
          </div>
        </div>
        <div>
          <span class="option_txt">원자재명</span>
          <div class="input_search">
            <input
              type="text"
              placeholder="원자재명 또는 규격 검색"
              :value="search_value"
              @input="typing"
            />
            <button>
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
        <div>
          <span class="option_txt">비고</span>
          <div class="input_search">
            <input
              type="text"
              placeholder="비고내용 검색"
              :value="detail_value"
              @input="typingDetail"
            />
            <button>
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
      <h6>조회수 : {{ filtered_materials.length }}건</h6>
      <div class="input_checkbox" v-if="systemCompany['group_material_yn']">
        <label for="checkbox2" class="label_txt">그룹 자재만 보기</label>
        <label
          for="checkbox2"
          class="chk_box"
          :class="check_only_group_material ? 'active' : ''"
        >
          <i class="fa fa-check"></i>
        </label>
        <input
          type="checkbox"
          id="checkbox2"
          @click="
            selectRow(-1);
            $store.commit(
              'setCheckOnlyGroupMaterial',
              !check_only_group_material,
            );
          "
        />
      </div>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl" :class="{ manage: managementMode }">
          <colgroup>
            <col
              v-for="(n, index) in managementMode
                ? systemCompany['group_material_yn']
                  ? 10
                  : 9
                : systemCompany['group_material_yn']
                ? 9
                : 8"
              :key="index"
            />
          </colgroup>
          <thead>
            <tr>
              <th>구분</th>
              <th class="text_left">원자재명</th>
              <th>규격</th>
              <th v-if="systemCompany['group_material_yn']">그룹</th>
              <th>입고단위</th>
              <th>사용단위</th>
              <th>구매처</th>
              <th>수입검사</th>
              <th>과세 여부</th>
              <th v-show="managementMode">
                {{ search_type == 9 ? '복구' : '삭제' }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(material, index) in filtered_materials"
              :key="material.id"
              @click="selectRow(index)"
              :class="selectedIndex == index ? 'active' : ''"
            >
              <td>{{ getResourceTypeName(material.resource_type_id) }}</td>
              <td class="text_left">{{ material.name }}</td>
              <td class="text_left">{{ material.standard }}</td>
              <td v-if="systemCompany['group_material_yn']">
                {{ material.material_group_yn ? 'O' : 'X' }}
              </td>
              <td>
                {{ findInfoFromId(units, material.incoming_unit_id).name }}
              </td>
              <td>
                {{ findInfoFromId(units, material.using_unit_id).name }}
              </td>
              <td class="text_left">
                {{
                  material.material_group_yn
                    ? getMaterialGroupName(material.material_group_list)
                    : getPrimaryCompanyName(material.company_list)
                }}
              </td>
              <td>
                {{
                  inspection_options.find(x => x.value == material.inspection)
                    .label
                }}
              </td>
              <td>
                {{ tax_options.find(x => x.value == material.tax).label }}
              </td>
              <!-- <td>
                {{ material.detail }}
              </td> -->
              <td v-show="managementMode && search_type != 9">
                <button class="tbl_delete_btn" @click="ShowModal()">
                  delete
                </button>
              </td>
              <td
                v-show="managementMode && search_type == 9"
                class="tbl_restore"
              >
                <button class="btn_tbl" @click="restoreMaterial(material)">
                  {{ `◀` }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="aside_management_mode">
      <div class="head">
        <h5>세부정보</h5>
        <button
          class="btn_sub1"
          v-if="
            selectedIndex != -1 &&
              managementData.bargetSystemCompanycode != '' &&
              managementData.barcode != null
          "
          @click="isBarcodeModalOpen = true"
        >
          바코드 출력
        </button>
        <material-barcode-print-modal
          v-if="isBarcodeModalOpen"
          @onclose="isBarcodeModalOpen = false"
          :printList="managementData"
        ></material-barcode-print-modal>
        <div
          class="modal_layer"
          v-if="isBarcodeModalOpen"
          @click="isBarcodeModalOpen = false"
        ></div>
        <button
          class="btn_sub1"
          v-show="selectedIndex != -1"
          v-if="isPermissionOn"
          @click="copyData()"
        >
          복사 후 신규등록
        </button>
      </div>
      <div class="form" @submit.prevent>
        <form>
          <div>
            <div class="input_text">
              <label
                class="require"
                :class="{
                  base_info: checkValidate.includes('원자재 구분') && baseInfo,
                }"
                >원자재 구분</label
              >
              <my-selectric
                v-if="showSelectric"
                :id="'material_type_selectric'"
                :watch="managementData.resource_type_id"
                :options="material_options"
                :commit="'setManagementMaterialTypeId'"
                :class="managementMode && selectedIndex != -1 ? '' : 'disabled'"
              >
              </my-selectric>
            </div>
            <div class="input_text">
              <label
                class="require"
                :class="{
                  base_info: checkValidate.includes('원자재명') && baseInfo,
                }"
                >원자재명</label
              >
              <input
                v-model="managementData.name"
                type="text"
                placeholder="과당"
                required
                :disabled="!managementMode || selectedIndex == -1"
              />
            </div>
          </div>
          <div>
            <div class="input_text">
              <label>규격</label>
              <input
                type="text"
                placeholder="1Box / 20Kg 5EA"
                :disabled="!managementMode || selectedIndex == -1"
                v-model="managementData.standard"
              />
            </div>
            <div
              class="input_checkbox"
              v-if="systemCompany['group_material_yn']"
            >
              <label for="checkbox9" class="label_txt">그룹 자재</label>
              <label
                for="checkbox9"
                class="chk_box"
                :class="managementData.material_group_yn ? 'active' : ''"
              >
                <i class="fa fa-check"></i>
              </label>
              <input
                type="checkbox"
                id="checkbox9"
                @click="
                  managementData.material_group_yn = !managementData.material_group_yn
                "
                :disabled="!managementMode || selectedIndex == -1"
              />
            </div>
          </div>
          <div>
            <div class="input_text" v-show="!managementData.material_group_yn">
              <label
                class="require"
                :class="{
                  base_info: checkValidate.includes('입고단위') && baseInfo,
                }"
                >입고단위</label
              >
              <my-selectric
                v-if="showSelectric"
                :id="'incoming_unit_selectric'"
                :watch="managementData.incoming_unit_id"
                :options="unit_options"
                :commit="'setManagementMaterialUnitsIncoming'"
                :class="managementMode && selectedIndex != -1 ? '' : 'disabled'"
              >
              </my-selectric>
            </div>
            <div class="input_text">
              <label
                class="require"
                :class="{
                  base_info: checkValidate.includes('사용단위') && baseInfo,
                }"
                >사용단위</label
              >
              <my-local-selectric
                v-if="showSelectric"
                :id="'using_unit_selectric'"
                :watch="managementData.using_unit_id"
                :options="unit_options"
                @changeValue="changeUnitsUsing($event)"
                :class="managementMode && selectedIndex != -1 ? '' : 'disabled'"
              >
              </my-local-selectric>
            </div>
            <p
              v-show="
                getConversionRatio != undefined &&
                  !managementData.material_group_yn
              "
              class="warning"
            >
              {{ getConversionRatio }}
            </p>
          </div>

          <div>
            <div
              class="input_text inputs"
              v-show="!managementData.material_group_yn"
            >
              <label>발주단위</label>
              <input
                type="text"
                placeholder="5"
                inputmode="decimal"
                @input="$inputNumber($event, managementData, 'order_unit')"
                :value="$makeComma(managementData.order_unit)"
                :disabled="!managementMode || selectedIndex == -1"
              />
              <label>{{
                managementData.incoming_unit_id != null &&
                managementData.using_unit_id != null
                  ? getUnitName(managementData.incoming_unit_id)
                  : ''
              }}</label>
            </div>
          </div>
          <div>
            <div
              class="input_text inputs"
              v-show="!managementData.material_group_yn"
            >
              <label>적정 재고량</label>
              <input
                type="text"
                placeholder="20"
                :value="$makeComma(managementData.stock_size)"
                inputmode="decimal"
                @input="$inputNumber($event, managementData, 'stock_size')"
                :disabled="!managementMode || selectedIndex == -1"
              />
              <label>{{
                managementData.incoming_unit_id != null &&
                managementData.using_unit_id != null
                  ? getUnitName(managementData.incoming_unit_id)
                  : ''
              }}</label>
            </div>
          </div>
          <div>
            <div class="input_text" v-show="!managementData.material_group_yn">
              <label
                class="require"
                :class="{
                  base_info: checkValidate.includes('수입검사') && baseInfo,
                }"
                >수입검사</label
              >
              <my-selectric
                v-if="showSelectric"
                :id="'inspection_selectric'"
                :watch="managementData.inspection"
                :options="inspection_options"
                :commit="'setManagementMaterialInspection'"
                :class="managementMode && selectedIndex != -1 ? '' : 'disabled'"
              >
              </my-selectric>
            </div>
            <div class="input_text" v-show="!managementData.material_group_yn">
              <label>창고</label>
              <my-selectric
                v-if="showSelectric"
                :id="'store_selectric'"
                :watch="managementData.default_store_id"
                :options="store_options"
                :commit="'setManagementMaterialDefaultStore'"
                :class="managementMode && selectedIndex != -1 ? '' : 'disabled'"
              >
              </my-selectric>
            </div>
          </div>
          <div></div>
          <div v-show="!managementData.material_group_yn">
            <div class="input_text">
              <label
                class="require"
                :class="{
                  base_info: checkValidate.includes('과세 여부') && baseInfo,
                }"
                >과세 여부</label
              >
              <my-selectric
                v-if="showSelectric"
                :id="'tax_selectric'"
                :watch="managementData.tax"
                :options="tax_options"
                :commit="'setManagementMaterialTax'"
                :class="managementMode && selectedIndex != -1 ? '' : 'disabled'"
              >
              </my-selectric>
            </div>
            <div
              class="input_text inputs"
              v-show="!managementData.material_group_yn"
            >
              <label>바코드</label>
              <input
                type="text"
                :value="managementData.barcode"
                maxlength="15"
                inputmode="decimal"
                @input="
                  $event.target.value = $event.target.value.replace(
                    /[^0-9]/gi,
                    '',
                  );
                  managementData.barcode = $event.target.value;
                "
                :disabled="!managementMode || selectedIndex == -1"
              />
            </div>
          </div>

          <div>
            <div
              class="input_text inputs add"
              v-show="!managementData.material_group_yn"
            >
              <label>구매처</label>
              <div>
                <draggable
                  class="selected_items_wrap"
                  :list="managementData.company_list"
                  delay-on-touch-only="true"
                  delay="80"
                  :disabled="!managementMode"
                >
                  <div
                    class="selected_item"
                    :class="index == 0 ? 'default' : ''"
                    v-for="(managementCompany,
                    index) in managementData.company_list"
                    :key="managementCompany.company_id"
                  >
                    <span>{{
                      getCompanyName(managementCompany.company_id)
                    }}</span
                    ><button
                      type="button"
                      class="delete_item"
                      @click.prevent.stop="deleteManagementCompany(index)"
                      v-show="managementMode && selectedIndex != -1"
                    ></button>
                  </div>
                </draggable>
                <div>
                  <button
                    type="button"
                    class="btn_ledger"
                    @click="showCompanySearch = true"
                    v-show="managementMode && selectedIndex != -1"
                  >
                    구매처 선택
                  </button>
                </div>
              </div>
            </div>
            <div
              class="input_text inputs add"
              v-show="managementData.material_group_yn"
            >
              <label>자재 그룹</label>
              <div>
                <draggable
                  class="selected_items_wrap"
                  v-model="managementData.material_group_list"
                >
                  <div
                    class="selected_item"
                    :class="index == 0 ? 'default' : ''"
                    v-for="(materialGroup,
                    index) in managementData.material_group_list"
                    :key="materialGroup.id"
                  >
                    <span
                      >{{
                        findInfoFromId(
                          materials,
                          materialGroup.member_material_id,
                        ).name
                      }}{{
                        findInfoFromId(
                          materials,
                          materialGroup.member_material_id,
                        ).standard.trim() != '' &&
                        findInfoFromId(
                          materials,
                          materialGroup.member_material_id,
                        ).standard != null
                          ? ` - 
                      ${
                        findInfoFromId(
                          materials,
                          materialGroup.member_material_id,
                        ).standard
                      }`
                          : ''
                      }}</span
                    ><button
                      type="button"
                      class="delete_item"
                      @click="
                        managementData.material_group_list.splice(index, 1)
                      "
                      v-show="managementMode && selectedIndex != -1"
                    ></button>
                  </div>
                </draggable>
                <div>
                  <button
                    type="button"
                    class="btn_ledger"
                    @click="doShowMaterialSearch()"
                    v-show="managementMode && selectedIndex != -1"
                  >
                    자재 선택
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="remark">
            <div class="input_text">
              <label>비고</label>
              <textarea
                v-model="managementData.detail"
                :disabled="!managementMode || selectedIndex == -1"
              >
              </textarea>
            </div>
          </div>
          <div class="btn_wrap">
            <button
              v-show="managementMode && selectedIndex != -1"
              type="button"
              class="btn_sub2"
              @click="submitForm()"
              :disabled="isValidModify"
            >
              <!-- :disabled="isValidModify || !checkValid" -->
              저장
            </button>
          </div>
        </form>
      </div>
    </div>
    <company-search
      v-if="showCompanySearch"
      :search_type="2"
      @onclose="showCompanySearch = false"
      @onselect="pushManagementCompany($event)"
    ></company-search>
    <resource-search
      v-if="showMaterialSearch"
      :filter_type="1"
      :ignore_list="ignore_list"
      @onclose="showMaterialSearch = false"
      @onselect="
        $event => {
          if (managementData.using_unit_id != $event.using_unit_id) {
            openOneButtonModal(
              '선택 불가',
              '그룹 자재와 포함 자재의 사용단위는 동일해야합니다.',
            );
            return;
          }
          managementData.material_group_list.push({
            group_material_id: managementData.id,
            member_material_id: $event.id,
            id: $event.id,
          });
          showMaterialSearch = false;
        }
      "
    ></resource-search>
    <two-button-modal
      z-index="9999"
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="CloseModal"
      @OnYesClick="deleteMaterial()"
    ></two-button-modal>
  </div>
</template>

<script>
import { clone } from '@/utils/func';
import MySelectric from '@/layouts/components/MySelectric';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';
import ModalMixin from '@/mixins/modal';
import FavoriteMixin from '@/mixins/favorite';
import SpinnerMixin from '@/mixins/spinner';
import CompanySearch from '@/layouts/components/search-popup/CompanySearch';
import ResourceSearch from '@/layouts/components/search-popup/ResourceSearch';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import draggable from 'vuedraggable';
// import { clone } from '@/utils/func';
import { mapGetters } from 'vuex';
import MaterialBarcodePrintModal from '@/layouts/components/MaterialBarcodePrintModal';

export default {
  mixins: [ModalMixin, SpinnerMixin, FavoriteMixin],
  data() {
    return {
      search_value: '',
      detail_value: '',
      showCompanySearch: false,
      showMaterialSearch: false,
      ignore_list: [],
      showSelectric: false,
      inspection_options: [
        { label: '선택', value: null },
        { label: '생략', value: false },
        { label: '필요', value: true },
      ],
      tax_options: [
        { label: '선택', value: null },
        { label: '과세', value: true },
        { label: '면세', value: false },
      ],

      //delete modal
      baseInfo: false,
      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,

      isBarcodeModalOpen: false,
    };
  },
  components: {
    MySelectric,
    CompanySearch,
    ResourceSearch,
    TwoButtonModal,
    draggable,
    MyLocalSelectric,
    MaterialBarcodePrintModal,
  },
  computed: {
    ...mapGetters({
      materials: 'getVisibleMaterial',
      del_materials: 'getDeletedMaterial',
      check_only_group_material: 'getCheckOnlyGroupMaterial',
      material_types: 'getMaterialType',
      material_options: 'getMaterialTypeForSelectricSelect',
      material_options_all: 'getMaterialTypeForSelectricAll',
      units: 'getUnitCodes',
      unit_conversions: 'getUnitConversion',
      unit_options: 'getUnitForSelectric',
      search_type: 'getSearchTypeFromMaterialPage',
      managementMode: 'getManagementModeFromMaterialPage',
      managementData: 'getManagementDataFromMaterial',
      store_options: 'getStoresForSelectric',
      stores: 'getStores',
      systemCompany: 'getSystemCompany',
      selectedIndex: 'getSelectedIndexFromMaterial',
      companys: 'getCompany',
    }),
    getConversionRatio() {
      if (
        this.managementData.incoming_unit_id ==
        this.managementData.using_unit_id
      ) {
        return undefined;
      }

      let ratio = this.unit_conversions.find(
        x =>
          x.in_unit_id == this.managementData.incoming_unit_id &&
          x.out_unit_id == this.managementData.using_unit_id,
      );
      return ratio == undefined
        ? '단위 환산식이 정의되어 있지 않습니다. 1:1 관계로 계산됩니다.'
        : `환산단위 : ${ratio.cvr_ratio}`;
    },
    filtered_materials() {
      const Hangul = require('hangul-js');
      return this.lodash
        .clonedeep(this.search_type == 9 ? this.del_materials : this.materials)
        .filter(
          x =>
            x.resource_type_id == this.search_type ||
            this.search_type == null ||
            this.search_type == 9,
        )
        .filter(
          x =>
            x.name.includes(this.search_value) ||
            Hangul.d(x.name, true)
              .map(x => x[0])
              .join('')
              .includes(this.search_value) ||
            x.standard.includes(this.search_value) ||
            Hangul.d(x.standard, true)
              .map(x => x[0])
              .join('')
              .includes(this.search_value),
        )
        .filter(
          x =>
            x.detail.includes(this.detail_value) ||
            Hangul.d(x.detail, true)
              .map(x => x[0])
              .join('')
              .includes(this.detail_value),
        )
        .filter(x => !this.check_only_group_material || x.material_group_yn)
        .sort((a, b) => {
          return a.name < b.name ? -1 : a.name == b.name ? 0 : 1;
        });
    },
    // checkValid() {
    //   if (
    //     this.managementData == undefined ||
    //     this.managementData == null ||
    //     this.managementData.name == undefined ||
    //     this.managementData.name == null ||
    //     this.managementData.name.trim().length == 0 ||
    //     this.managementData.resource_type_id == undefined ||
    //     this.managementData.resource_type_id == null ||
    //     this.managementData.using_unit_id == undefined ||
    //     this.managementData.using_unit_id == null ||
    //     (!this.managementData.material_group_yn &&
    //       (this.managementData.inspection == undefined ||
    //         this.managementData.inspection == null ||
    //         this.managementData.tax == undefined ||
    //         this.managementData.tax == null ||
    //         this.managementData.incoming_unit_id == undefined ||
    //         this.managementData.incoming_unit_id == null))
    //   ) {
    //     return false;
    //   } else return true;
    // },
    checkValidate() {
      let chk = [];

      if (
        this.managementData.name == undefined ||
        this.managementData.name.trim() == ''
      ) {
        chk.push('원자재명');
      }

      if (
        this.managementData.resource_type_id == undefined ||
        this.managementData.resource_type_id == null
      ) {
        chk.push('원자재 구분');
      }

      if (
        this.managementData.incoming_unit_id == undefined ||
        this.managementData.incoming_unit_id == null
      ) {
        chk.push('입고단위');
      }
      if (
        this.managementData.using_unit_id == undefined ||
        this.managementData.using_unit_id == null
      ) {
        chk.push('사용단위');
      }
      if (
        this.managementData.inspection == undefined ||
        this.managementData.inspection == null
      ) {
        chk.push('수입검사');
      }
      if (
        this.managementData.tax == undefined ||
        this.managementData.tax == null
      ) {
        chk.push('과세여부');
      }

      return chk;
    },
    isValidModify() {
      if (this.managementMode && this.selectedIndex != -1) {
        let modifyData = this.lodash.clonedeep(this.managementData);
        modifyData.name = modifyData.name.trim();
        modifyData.standard = modifyData.standard.trim();
        modifyData.order_unit = this.$makeNumber(modifyData.order_unit);
        modifyData.stock_size = this.$makeNumber(modifyData.stock_size);

        modifyData = JSON.stringify(modifyData);

        let originData = this.lodash.clonedeep(
          this.filtered_materials[this.selectedIndex],
        );
        if (this.managementData.material_group_yn) {
          originData.material_group_list.forEach(el => {
            el.id = el.member_material_id;
          });
        }

        originData = JSON.stringify(originData);

        if (modifyData == originData) {
          return true;
        } else return false;
      } else return true;
    },
    validName() {
      const materialListChk = this.lodash
        .clonedeep(this.materials)
        .find(
          x =>
            x.id != this.managementData.id &&
            x.name == this.managementData.name.trim() &&
            x.standard == this.managementData.standard.trim(),
        );

      if (materialListChk != undefined) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async restoreMaterial(mat) {
      this.showSpinner();
      await this.$store
        .dispatch('RESTORE_MATERIAL', mat.id)
        .then(async () => {
          this.$store.commit('setSelectedIndexToMaterial', -1);
          this.$store.commit('setManagementDataToMaterial', {
            name: '',
            detail: '',
            standard: '',
            inspection: null,
            stock_size: 0,
            tax: null,
            resource_type_id: null,
            incoming_unit_id: null,
            using_unit_id: null,
            default_store_id: null,
            order_unit: 0,
          });
          await this.FETCHS('FETCH_MATERIAL_WITH_COMPANY');
        })
        .catch(() => {
          this.openOneButtonModal('복구실패', '원자재를 복구하는 중 오류발생');
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    typing(e) {
      this.search_value = e.target.value;
      this.selectRow(-1);
    },
    typingDetail(e) {
      this.detail_value = e.target.value;
      this.selectRow(-1);
    },
    copyData() {
      let cloneManagementData = clone(this.managementData);
      cloneManagementData.name = cloneManagementData.name + '_복사됨';
      this.$store.commit('setNewDataToMaterial', cloneManagementData);
      let arrTemp = clone(this.managementData.company_list);
      arrTemp.forEach(el => {
        el.id = el.company_id;
        el.name = this.getCompanyName(el.company_id);
      });

      this.$store.commit('setNewMaterialCompany', arrTemp);
      this.$store.commit('setOpenTabIndexToMaterialPage', 1);
    },
    doShowMaterialSearch() {
      const clone = this.lodash.clonedeep(
        this.managementData.material_group_list,
      );
      clone.forEach(el => {
        el.resource_type_id = this.findInfoFromId(
          this.materials,
          el.id,
        ).resource_type_id;
      });
      this.ignore_list = clone;

      this.showMaterialSearch = true;
    },
    ShowModal(index) {
      this.my_modal_index = index;
      this.my_modal_title = '경고';
      this.my_modal_content = '정말로 삭제하시겠습니까?';
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_modal_index = -1;
      this.my_modal_title = '';
      this.my_modal_content = '';
      this.my_isModalOpen = false;
    },
    selectRow(index) {
      this.$store.commit('setSelectedIndexToMaterial', index);
      this.ignore_list = [];
      if (index != -1) {
        this.$store.commit(
          'setManagementDataToMaterial',
          this.lodash.clonedeep(this.filtered_materials[index]),
        );
      } else {
        this.$store.commit('setManagementDataToMaterial', {
          id: -1,
          name: '',
          detail: '',
          standard: '',
          inspection: null,
          stock_size: 0,
          tax: null,
          barcode: '',
          resource_type_id: null,
          incoming_unit_id: null,
          using_unit_id: null,
          default_store_id: null,
          order_unit: 0,
          company_list: [],
          material_group_list: [],
        });
      }
    },
    getUnitName(id) {
      let hitValue = this.unit_options.find(x => x.value == id);
      return hitValue == undefined ? '' : hitValue.label;
    },
    getCompanyName(id) {
      let hitValue = this.companys.find(x => x.id == id);
      return hitValue == undefined ? '' : hitValue.name;
    },
    getPrimaryCompanyName(arr) {
      if (arr.length < 1) return '';

      let hitValue = this.companys.find(
        x => x.id == arr.find(y => y.primary_comp == true).company_id,
      );
      return hitValue == undefined
        ? ''
        : hitValue.name +
            (arr.length > 1 ? ` 외 ${arr.length - 1}개 업체` : '');
    },
    getResourceTypeName(id) {
      let hitValue = this.material_types.find(x => x.id == id);
      return hitValue == undefined ? '' : hitValue.name;
    },
    // toggleTax() {
    //   if (this.managementMode == true && this.selectedIndex != -1) {
    //     this.managementData.tax = !this.managementData.tax;
    //   }
    // },
    pushManagementCompany(arg) {
      if (
        this.managementData.company_list.find(x => x.company_id == arg.id) !=
        undefined
      ) {
        this.showCompanySearch = false;
        return;
      }
      this.managementData.company_list.push({
        company_id: arg.id,
        material_id: this.managementData.id,
        primary_comp:
          this.managementData.company_list.length == 0 ? true : false,
      });
      this.showCompanySearch = false;
    },
    deleteManagementCompany(index) {
      if (index == 0 && this.managementData.company_list.length > 1) {
        this.managementData.company_list[1].primary_comp = true;
      }
      this.managementData.company_list.splice(index, 1);
    },
    async deleteMaterial() {
      this.showSpinner();
      await this.$store
        .dispatch(
          'DELETE_MATERIAL',
          this.filtered_materials[this.selectedIndex].id,
        )
        .then(async () => {
          this.$store.commit('setSelectedIndexToMaterial', -1);
          this.$store.commit('setManagementDataToMaterial', {
            name: '',
            detail: '',
            standard: '',
            inspection: null,
            stock_size: 0,
            tax: null,
            resource_type_id: null,
            incoming_unit_id: null,
            using_unit_id: null,
            default_store_id: null,
            order_unit: 0,
          });
          await this.FETCHS('FETCH_MATERIAL_WITH_COMPANY');
        })
        .catch(() => {
          this.openOneButtonModal('삭제 중 오류', '자원 삭제 중 오류');
        })
        .finally(() => {
          this.CloseModal();
          this.hideSpinner();
        });
    },
    async submitForm() {
      if (this.checkValidate.length > 0) {
        this.openOneButtonModal(
          '내용 입력',
          `${this.checkValidate.join()}은(는) 반드시 입력하여 주십시오.`,
        );
        this.baseInfo = true;
        return;
      }

      if (this.validName) {
        this.openOneButtonModal(
          '수정 불가',
          `같은 이름의 원자재가 등록이 되어있습니다.<br />다른 원자재명을 등록해주세요.`,
        );
        return;
      }

      this.showSpinner();
      let payload = this.lodash.clonedeep(this.managementData);
      if (payload.material_group_yn) {
        payload.incoming_unit_id = payload.using_unit_id;
        payload.inspection = false;
        payload.tax = false;
      }
      payload.name = payload.name.trim();
      payload.standard = payload.standard.trim();
      payload.order_unit = this.$makeNumber(payload.order_unit);
      payload.stock_size = this.$makeNumber(payload.stock_size);

      await this.$store
        .dispatch('UPDATE_MATERIAL', payload)
        .then(async () => {
          await this.$store
            .dispatch('DELETE_MATERIAL_COMPANY_ALL', this.managementData.id)
            .then(async () => {
              await this.$store
                .dispatch('DELETE_MATERIAL_GROUP_ALL', this.managementData.id)
                .then(async () => {
                  if (this.managementData.company_list.length > 0) {
                    this.managementData.company_list.forEach(el => {
                      el.primary_comp = false;
                    });
                    this.managementData.company_list[0].primary_comp = true;
                    await this.$store
                      .dispatch('INSERT_MATERIAL_COMPANY_LIST', {
                        data_list: this.managementData.company_list,
                      })
                      .then(() => {})
                      .catch(error => {
                        console.log(error);
                      });
                  }
                  if (this.managementData.material_group_list.length > 0) {
                    await this.$store
                      .dispatch('INSERT_MATERIAL_GROUP_LIST', {
                        data_list: this.managementData.material_group_list,
                      })
                      .then(() => {})
                      .catch(error => {
                        console.log(error);
                      });
                  }
                })
                .catch(() => {})
                .finally(() => {
                  this.openOneButtonModal(
                    '수정 성공',
                    '성공적으로 수정하였습니다.',
                  );
                  this.baseInfo = false;
                  this.FETCH_WITH_PAYLOAD(
                    'FETCH_MATERIAL_WITH_COMPANY',
                    '원자재',
                  );
                  this.selectRow(-1);
                });
            })
            .catch(error => {
              console.log(error);
            });
        })
        .catch(() => {
          this.openOneButtonModal('수정 중 오류', '자원 수정 중 오류');
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    async FETCHS(apiName) {
      this.showSpinner();
      await this.$store
        .dispatch(apiName)
        .then(() => {})
        .catch(() => {
          this.openOneButtonModal(
            '로드 중 오류',
            '자원 관련 정보 로드 중 오류',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    changeUnitsUsing(value) {
      this.$store.commit('setManagementMaterialUnitsUsing', value);
      this.$store.commit('setManagementMaterialmaterialGroupList', []);
    },
    changeSelectric(type) {
      this.selectRow(-1);
      this.$store.commit('setSearchTypeToMaterialPage', type);
    },
    getMaterialGroupName(groupList) {
      if (groupList.length < 1) return '';

      let hitValue = this.materials.find(
        x => x.id == groupList[0].member_material_id,
      );
      return hitValue == undefined
        ? ''
        : hitValue.name +
            (groupList.length > 1 ? ` 외 ${groupList.length - 1}개 자재` : '');
    },
  },
  watch: {
    managementData(newValue) {
      if (newValue.material_group_yn) {
        newValue.material_group_list.forEach(el => {
          el.id = el.member_material_id;
        });
      }
    },
  },
  async created() {
    if (this.companys.length == 0) {
      await this.FETCHS('FETCH_COMPANY');
    }
    if (this.material_types.length == 0) {
      await this.FETCHS('FETCH_RESOURCE_TYPE');
    }
    if (this.units.length == 0) {
      await this.FETCHS('FETCH_UNIT');
    }
    if (this.unit_conversions.length == 0) {
      await this.FETCHS('FETCH_UNIT_CONVERSION');
    }
    if (this.stores.length == 0) {
      await this.FETCHS('FETCH_STORE');
    }
    if (this.materials.length == 0) {
      await this.FETCHS('FETCH_MATERIAL_WITH_COMPANY');
    }
    if (
      this.managementData.id != undefined &&
      this.managementData.id != null &&
      this.managementData.id != -1
    ) {
      this.$store.commit(
        'setSelectedIndexToMaterial',
        this.filtered_materials.findIndex(x => x.id == this.managementData.id),
      );
    }
    this.showSelectric = true;
  },
};
</script>

<style lang="scss" scoped></style>
