<template>
  <div>
    <div class="modalPopup" id="company_search_modal">
      <div class="modal_header">
        <h3 class="title">{{ getCompanyTypeName(search_type) }} 검색</h3>
        <button class="modal_close" @click="isModalClose"></button>
      </div>
      <div class="modal_body">
        <div class="modal_search_opt">
          <div class="input_search">
            <input
              type="text"
              :placeholder="`${getCompanyTypeName(search_type)} 검색`"
              :value="search_value"
              @input="typing"
            />
            <button>
              <i class="fa fa-search"></i>
            </button>
          </div>
          <!-- <div class="input_checkbox">
            <label
              for="checkbox4"
              class="chk_box"
              onclick="selectedCheckbox('#checkbox4')"
            >
              <i class="fa fa-check"></i>
            </label>
            <input type="checkbox" id="checkbox4" checked />
            <label
              for="checkbox4"
              class="label_txt"
              onclick="selectedCheckbox('#checkbox4')"
              >원자재</label
            >
          </div> -->
        </div>
        <div class="mes_tbl_wrap">
          <table class="mes_tbl">
            <colgroup>
              <col v-for="(n, index) in 4" :key="index" />
            </colgroup>
            <thead>
              <tr>
                <th>구분</th>
                <th>거래처명</th>
                <th>거래개시일</th>
                <th>부가세구분</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(company, index) in filtered_companys"
                :key="company.id"
                @click="selectedIndex = index"
                @dblclick="submit()"
                :class="selectedIndex == index ? 'active' : ''"
              >
                <td>
                  {{ getCompanyTypeName(company.company_type_id) }}
                </td>
                <td>{{ company.name }}</td>
                <td>{{ company.business_date }}</td>
                <td>{{ getVatName(company.vat) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal_footer">
        <div class="warning" v-show="error_text != ''">
          {{ error_text }}
        </div>
        <button type="button" class="btn_sub2" @click="submit()">
          선택
        </button>
      </div>
    </div>
    <div class="modal_layer" @click="isModalClose"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SpinnerMixin from '@/mixins/spinner';
import ModalMixin from '@/mixins/modal';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
export default {
  mixins: [SpinnerMixin, ModalMixin, DRAG_MODAL_MIXIN],
  props: {
    search_type: {
      type: Number,
      required: true,
    },
    ignore_company_list: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      error_text: '',
      selectedIndex: -1,
      search_value: '',
      company_types: [
        {
          id: 1,
          name: '매출처',
        },
        {
          id: 2,
          name: '구매처',
        },
      ],
      vats: [
        {
          id: 1,
          name: '부가세별도',
        },
        {
          id: 2,
          name: '부가세포함',
        },
        {
          id: 3,
          name: '영세율',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      companys: 'getVisibleCompany',
    }),
    filtered_companys() {
      const Hangul = require('hangul-js');
      if (this.ignore_company_list != undefined) {
        return this.companys
          .filter(x => x.company_type_id == this.search_type)
          .filter(
            x =>
              x.name.includes(this.search_value) ||
              Hangul.d(x.name, true)
                .map(x => x[0])
                .join('')
                .includes(this.search_value),
          )
          .filter(x => this.ignore_company_list.includes(x.id));
      } else {
        return this.companys
          .filter(x => x.company_type_id == this.search_type)
          .filter(
            x =>
              x.name.includes(this.search_value) ||
              Hangul.d(x.name, true)
                .map(x => x[0])
                .join('')
                .includes(this.search_value),
          );
      }
    },
  },
  methods: {
    isModalClose() {
      this.$emit('onclose');
    },
    getCompanyTypeName(id) {
      return this.company_types.find(x => x.id == id).name;
    },
    getVatName(id) {
      return this.vats.find(x => x.id == id).name;
    },
    async FETCH_COMPANY() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_COMPANY', this.date)
        .then(response => {
          console.log(response);
        })
        .catch(response => {
          console.log(response);
          this.error_text = '거래처 로드 중 에러 발생. 다시 로그인해주십시오.';
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    typing(e) {
      this.search_value = e.target.value.trim();
      this.selectedIndex = -1;
    },
    submit() {
      if (this.selectedIndex == undefined || this.selectedIndex < 0) {
        this.error_text = `테이블의 행을 클릭하여 ${this.getCompanyTypeName(
          this.search_type,
        )}을 선택하여주십시오.`;
        return;
      }
      this.$emit('onselect', this.filtered_companys[this.selectedIndex]);
    },
  },
  async created() {
    await this.FETCH_COMPANY();
  },
};
</script>

<style></style>
