<template>
  <div class="modalPopup print_modal material_barcode_modal">
    <div class="modal_header">
      <h2 class="title">
        원자재 바코드
      </h2>
      <button type="button" class="modal_close" @click="isModalClose"></button>
    </div>
    <div class="modal_body">
      <ul>
        <li>
          <h6>
            자재명
          </h6>
          <p>
            {{ printList.name }}
          </p>
        </li>
        <li>
          <h6>
            규격
          </h6>
          <p>
            {{ printList.standard }}
          </p>
        </li>
        <li>
          <h6>바코드</h6>
          <ul>
            <li>
              <p class="barcode">{{ `*${printList.barcode}*` }}</p>
            </li>
            <li>
              <p class="barcode_number">
                {{ printList.barcode }}
              </p>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="modal_footer">
      <button type="button" class="btn_sub2 btn_print" @click="handlerPrint">
        <i class="ico_print"></i>출력하기
      </button>
    </div>
  </div>
</template>

<script>
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';

export default {
  mixins: [DRAG_MODAL_MIXIN],
  props: ['printList'],
  methods: {
    isModalClose() {
      this.$emit('onclose');
    },
    handlerPrint() {
      const app = document.querySelector('#app');
      const printContents = document.querySelector('.print_modal .modal_body');

      const printDiv = document.createElement('div');
      printDiv.className = 'material_print_wrap';
      let printContentsClone = printContents.cloneNode(true);
      document.body.appendChild(printDiv);
      printDiv.appendChild(printContentsClone);
      app.style.display = 'none';
      window.print();
      app.style.display = 'block';
      document.body.removeChild(printDiv);
    },
  },
};
</script>

<style lang="scss" scoped>
@media print {
  @page {
    // size: 8cm 5cm landscape !important;
    margin: 0mm !important;
  }
}
</style>
